div.mapboxgl-popup {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  z-index: 1;
}
div.mapboxgl-popup span {
  display: block;
}
div.mapboxgl-popup span.scroll {
  max-height: 150px;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  a.mapboxgl-ctrl-logo {
    display: none;
  }
}

